import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  title?: string;
}>;

const Group: FC<Props> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-12 py-16">
      {title && <p className="font-bold text-secondary text-2xs">{title}</p>}
      <div>{children}</div>
    </div>
  );
};
export default Group;
