import { RootState } from "@/stores/rootReducer";
import { useMemo } from "react";
import { useSelector } from "react-redux";

// アカウント情報が完全かどうかをチェックする
const useCompleteAccountCheck = (): boolean => {
  const { user } = useSelector((state: RootState) => state.auth);

  const complete = useMemo(() => {
    if (!user) return true;

    const { industry, occupation } = user;

    return industry !== undefined && occupation !== undefined;
  }, [user]);

  return complete;
};
export default useCompleteAccountCheck;
