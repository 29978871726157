const black = {
  950: "#000",
};

const white = {
  50: "#FFFFFF",
};

const gray = {
  50: "#F5F6F6",
  100: "#EBECED",
  200: "#D7D9DA",
  300: "#B5B8BA",
  400: "#9CA1A3",
  500: "#8B9093",
  600: "#686F73",
  700: "#485056",
  800: "#394248",
  900: "#141F25",
  950: "#08131A",
};

const yellow = {
  50: "#FFFFE7",
  100: "#FEFFC1",
  200: "#FFFC86",
  300: "#FFF241",
  400: "#FFE30D",
  500: "#FFD400",
  600: "#D19C00",
  700: "#A66F02",
  800: "#89560A",
  900: "#74470F",
  950: "#442504",
};

const violet = {
  50: "#F4F1FF",
  100: "#EBE6FF",
  200: "#D9D0FF",
  300: "#BDABFF",
  400: "#9E7BFF",
  500: "#8146FF",
  600: "#7320FF",
  700: "#6D1AF3",
  800: "#550CCB",
  900: "#470CA6",
  950: "#290471",
};

// TODO: define
const green = {
  300: "#00EE88",
  500: "#1CA47F",
};

// TODO: define
const red = {
  500: "#DC0000",
};

module.exports = {
  future: {
    hoverOnlyWhenSupported: true,
  },
  purge: [
    "./pages/**/*.tsx",
    "./src/pages/**/*.tsx",
    "./src/components/**/*.tsx",
    "./src/layouts/**/*.tsx",
    "./src/features/**/*.tsx",
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      laptop: {
        min: "835px",
      },
      small: {
        max: "834px",
      },
    },
    fontSize: {
      tiny: ["1.0rem"],
      "2xs": ["1.1rem"],
      xs: ["1.2rem"],
      sm: ["1.4rem"],
      base: ["1.6rem"],
      lg: ["1.8rem"],
      xl: ["2.0rem"],
      "2xl": ["2.4rem"],
      "3xl": ["2.8rem"],
      "4xl": ["3.2rem"],
      // 読み物系
      reading: [
        "1.4rem",
        {
          lineHeight: 2.0,
          letterSpacing: "0.08em",
        },
      ],
    },
    colors: {
      transparent: "transparent",
      white: white["50"],
      black: black["950"],
      gray,
      yellow,
      red,
      green,
      brand: {
        black: black["950"],
        white: white["50"],
        yellow: yellow["500"],
      },
      sns: {
        facebook: "#0084FF",
        twitter: "#1DA1F2",
      },
      indirectDeliver: {
        normal: violet["700"],
        light: violet["100"],
      },
      like: "#F0584E",
      warning: {
        normal: yellow["400"],
        light: yellow["200"],
      },
      valid: green["500"],
      danger: red["500"],
    },
    backgroundColor: (theme) => ({
      ...theme("colors"), // TODO: いつか消したい
      input: {
        primary: white["50"],
        secondary: gray["50"],
      },
      main: gray["50"],
      overlay: "rgba(0 0 0 / 70%)",
      primary: white["50"],
      secondary: gray["950"],
      info: "#21AFFF",
    }),
    textColor: (theme) => ({
      ...theme("colors"), // TODO: いつか消したい
      primary: gray["950"],
      secondary: gray["600"],
      tertiary: gray["300"],
      disable: gray["200"],
      link: "#21A0CF",
      danger: "#DC0000",
      placeholder: gray["300"],
    }),
    borderColor: (theme) => ({
      ...theme("colors"), // TODO: いつか消したい
      primary: gray[100],
      secondary: gray[950],
    }),
    outlineColor: (theme) => ({
      ...theme("colors"), // TODO: いつか消したい
      primary: gray[200],
      secondary: gray[950],
    }),
    boxShadow: {
      default: "0 0 8px rgba(0, 0, 0, 0.25)",
      under: "0 4px 8px rgba(0, 0, 0, 0.25)",
      card: "0 0 12px 8px rgba(0, 0, 0, 0.1)",
      modal: "0 0 12px 4px rgba(0, 0, 0, 0.25)",

      xs: "0px 0px 3.5px 0px rgb(8 18 26 / 0.12)",
      sm: "0px 0px 7.75px 0px rgb(8 18 26 / 0.12)",
      md: "0px 0px 10.5px 0px rgb(8 18 26 / 0.12)",
      lg: "0px 0px 14.25px 0px rgb(8 18 26 / 0.12)",
      xl: "0px 0px 21.5px 0px rgb(8 18 26 / 0.12)",
      "2xl": "0px 0px 28px 0px rgb(8 18 26 / 0.12)",
    },
    borderRadius: {
      none: "0",
      xxs: "2px",
      xs: "4px",
      sm: "8px",
      md: "12px",
      lg: "16px",
      full: "9999px",
    },
    // デフォルトの値も含める場合
    extend: {
      spacing: {
        2: "2px",
        4: "4px",
        6: "6px",
        8: "8px",

        12: "12px",
        16: "16px",
        20: "20px",
        24: "24px",

        32: "32px",
        40: "40px",
        48: "48px",

        64: "64px",
        80: "80px",

        100: "100px",
        120: "120px",

        160: "160px",
        200: "200px",
      },
      lineHeight: {
        "extra-loose": "2.5",
      },
      zIndex: {
        modal: 9999,
        progress: 10000,
        snackbar: 10001,
      },
      aspectRatio: {
        "4/3": "4 / 3",
        "32/9": "32 / 9",
        "7/9": "7 / 9",
        "5/2": "5 / 2",
      },
      transitionDuration: {
        240: "240ms",
      },
      backgroundImage: {
        "black-gradient":
          "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
      },
    },
    dropShadow: {
      icon: "0px 0px 2px rgba(8, 18, 26, 0.30)",
    },
  },
  variants: {
    extend: {
      scale: ["group-hover"],
    },
  },
  plugins: [require("@tailwindcss/container-queries")],
};
