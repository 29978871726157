import Group from "@/components/TheAccountNavigation/components/Group";
import Link from "next/link";
import { FC } from "react";

export type LinkType = {
  href?: string;
  label?: string;
  render?: () => JSX.Element;
};

type Props = {
  title?: string;
  links: LinkType[];
};

const LinkGroup: FC<Props> = ({ title, links }) => {
  return (
    <Group title={title}>
      <ul className="space-y-12">
        {links.map(({ href, label, render }, index) => (
          <li key={label || href || index}>
            {label && <Link href={href}>{label}</Link>}
            {!label && render && <>{render()}</>}
          </li>
        ))}
      </ul>
    </Group>
  );
};
export default LinkGroup;
