import useProgress from "@/hooks/useProgress";
import { auth } from "@/utils/firebase";
import { signOut } from "firebase/auth";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { clear } from "@/stores/auth";
import { useRouter } from "next/router";

const LogoutBtn: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const progress = useProgress();

  const logoutHandler = () => {
    progress(async () => {
      try {
        await signOut(auth);
        dispatch(clear());
        window.alert("ログアウトしました");
        router.push("/");
      } catch (e) {
        alert(e.message);
      }
    });
  };

  return (
    <button onClick={logoutHandler} className="text-danger font-bold">
      ログアウト
    </button>
  );
};

export default LogoutBtn;
