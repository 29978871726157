import { RootState } from "@/stores/rootReducer";
import Link from "next/link";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import BasicButton, { BasicButtonStyle } from "@/components/BasicButton";
import useBtnRequiresIsDesigner from "@/features/user/hooks/useBtnRequiresDesigner";
import { useRouter } from "next/router";
import IconWarning from "@/assets/imgs/svg/icon-warning.svg";
import { theme } from "tailwind.config";
import useCompleteAccountCheck from "@/hooks/useCompleteAccountCheck";
import useUserStatus from "@/features/user/hooks/useUserStatus";
import TeamGroup from "@/components/TheAccountNavigation/components/TeamGroup";
import Maybe from "@/components/Maybe";
import LinkGroup, {
  LinkType,
} from "@/components/TheAccountNavigation/components/LinkGroup";
import UserIcon from "@/features/user/components/UserIcon";
import LogoutBtn from "@/components/LogoutBtn";
import useDisclosure from "@/hooks/useDisclosure";
import dynamic from "next/dynamic";

const DynamicDialogJoinTeam = dynamic(
  () => import("@/features/team/components/DialogJoinTeam"),
);

const TheAccountNavigation: FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const router = useRouter();
  const completeAccount = useCompleteAccountCheck();
  const { hasTeam, isDesigner } = useUserStatus();

  const {
    isOpen: isDialogJoinTeamOpen,
    openHandler: openDialogJoinTeam,
    closeHandler: closeDialogJoinTeam,
  } = useDisclosure();
  const { clickHandler: clickCreateProjectBtnHandler } =
    useBtnRequiresIsDesigner(() => {
      if (!hasTeam) {
        openDialogJoinTeam();

        return;
      }

      router.push("/projects/new");
    });

  const accountServiceLinks = useMemo<LinkType[]>(() => {
    const base = [
      { href: "/settings/account", label: "アカウント設定" },
      // あまりにも多いと、ナビゲーションが雑多になるので以下をあえて非表示とする
      // { href: "/settings/profile", label: "プロフィール設定" },
      // { href: "/settings/notification", label: "メールマガジンの設定" },
      // {
      //   href: "/signin?from=/settings/email&reason=change_email",
      //   label: "メールアドレス設定",
      // },
      // {
      //   href: "/signin?from=/settings/password&reason=change_password",
      //   label: "パスワード設定",
      // },
    ];

    return isDesigner
      ? [
          ...base,
          { href: "/settings/orders", label: "注文履歴" },
          // { href: "/settings/addresses", label: "お届け先" },
          // { href: "/settings/teams", label: "チーム" },
        ]
      : base;
  }, [isDesigner]);

  const contentLinks = useMemo<LinkType[]>(() => {
    return [
      { href: `/users/${user.id}/projects`, label: "プロジェクト" },
      { href: `/users/${user.id}/posts`, label: "写真" },
      { href: `/users/${user.id}/lists`, label: "リスト" },
      { href: `/users/${user.id}/likes`, label: "Likes" },
      { href: `/users/${user.id}/following`, label: "フォロー" },
      { href: `/users/${user.id}/followers`, label: "フォロワー" },
    ];
  }, [user]);

  return (
    <>
      <nav className="bg-white shadow-default rounded-md p-16 laptop:p-20 space-y-20 min-w-[240px] max-w-[300px] text-sm small:max-h-[320px] overflow-y-scroll">
        <div className="space-y-12">
          <div className="grid gap-8 grid-cols-[auto_1fr] overflow-hidden">
            <Link href={`/users/${user.id}/posts`} prefetch={false}>
              <UserIcon user={user} hasLink={false} />
            </Link>

            <div className="grid gap-4">
              <p className="leading-tight font-bold break-all">
                {user.screen_name}
              </p>
              <p className="text-gray text-xs">
                <Link href={`/users/${user.id}/posts`} prefetch={false}>
                  マイページを表示
                </Link>
              </p>
            </div>
          </div>

          {!completeAccount && (
            <Link
              href="/settings/account"
              className="border border-error p-8 gap-4 text-danger flex items-center hover:bg-danger hover:bg-opacity-20 rounded-xs"
            >
              <IconWarning fill={theme.colors.danger} width={16} height={16} />
              <span className="text-xs ">アカウント設定が未完了です</span>
            </Link>
          )}
        </div>

        <div className="[&>*]:border-t [&>*]:border-primary">
          <ul className="py-8 laptop:hidden">
            <li className="[&>*]:w-full">
              <BasicButton
                onClick={clickCreateProjectBtnHandler}
                style={BasicButtonStyle.UniqueAction}
                label={"プロジェクト投稿"}
              />
            </li>
          </ul>

          <div className="[&>*+*]:border-t [&>*+*]:border-primary">
            <LinkGroup title="アカウントサービス" links={accountServiceLinks} />
            <Maybe condition={user.team_members.length > 0}>
              <TeamGroup title="所属チーム" />
            </Maybe>
            <LinkGroup links={contentLinks} />
            <LinkGroup
              links={[
                {
                  render: () => {
                    return <LogoutBtn />;
                  },
                },
              ]}
            />
          </div>
        </div>
      </nav>

      <DynamicDialogJoinTeam
        isOpen={isDialogJoinTeamOpen}
        onClose={closeDialogJoinTeam}
      />
    </>
  );
};
export default TheAccountNavigation;
