import Avatar, { AvatarSize } from "@/components/Avatar";
import Group from "@/components/TheAccountNavigation/components/Group";
import useMyUser from "@/features/user/hooks/useMyUser";
import useTeamsByUserId from "@/features/team/hooks/useTeamsByUserId";
import Link from "next/link";
import { FC } from "react";
import UnauthorizedTeamChip from "@/features/team/components/UnauthorizedTeamChip";
import Maybe from "@/components/Maybe";
import { TeamStatus } from "@/web-client";

type Props = {
  title?: string;
};

const TeamGroup: FC<Props> = ({ title }) => {
  const { user } = useMyUser();
  const { teams } = useTeamsByUserId(user.id);

  return (
    <Group title={title}>
      <div className="grid gap-12">
        {teams.map((team) => (
          <div className="relative" key={team.id}>
            <div className="grid gap-4">
              <div className="flex items-center gap-8">
                <div className="flex-shrink-0">
                  <Avatar
                    src={
                      team.logo
                        ? team.logo.urls.small
                        : "/imgs/default_team_icon.png"
                    }
                    size={AvatarSize.Normal}
                    alt={team.name}
                  />
                </div>
                <p className="line-clamp-2 text-sm">{team.name}</p>
              </div>

              <Maybe condition={team.status === TeamStatus.PENDING}>
                <UnauthorizedTeamChip />
              </Maybe>
            </div>

            <Link
              className="absolute inset-0"
              href={`/teams/${team.id}/projects`}
              prefetch={false}
            />
          </div>
        ))}
      </div>
    </Group>
  );
};
export default TeamGroup;
