import client from "@/utils/api/client";
import { TeamMemberResponse, TeamResponse } from "@/web-client/api";
import { useMemo } from "react";
import useFetch from "@/hooks/useFetch";

type UseTeamsByUserIdResponse = {
  teams: TeamResponse[];
  teamMembers: TeamMemberResponse[];
  mutate(data?: TeamMemberResponse[], shouldRevalidate?: boolean);
};

const useTeamsByUserId = (id: number): UseTeamsByUserIdResponse => {
  const { data, mutate } = useFetch<TeamMemberResponse[]>(
    id ? `/users/${id}/teams` : null,
    async () => {
      // とりあえず999件取得
      const { data } = await client.usersIdTeamsGet({ id, limit: 999 });

      return data.results || [];
    },
    undefined,
    { waitForAuth: true },
  );

  const teams = useMemo(() => {
    if (!data) return [];

    return data.map((m) => m.team);
  }, [data]);

  return {
    teams,
    teamMembers: data,
    mutate,
  };
};

export default useTeamsByUserId;
