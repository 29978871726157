import { FC } from "react";
import Chip from "@/components/Chip";
import IconWarning from "@/assets/imgs/svg/iicon-round_warning.svg";

const UnauthorizedTeamChip: FC = (): JSX.Element => {
  return (
    <Chip
      label="チーム承認待ち"
      icon={<IconWarning width={16} height={16} />}
    />
  );
};
export default UnauthorizedTeamChip;
