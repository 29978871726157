import { FC, ReactNode } from "react";

type Props = {
  label: string;
  icon: ReactNode;
};

const Chip: FC<Props> = ({ label, icon }): JSX.Element => {
  return (
    <div className="flex justify-center gap-8 items-center rounded-full p-8 text-center bg-main text-xs text-primary leading-none">
      {icon}
      <span>{label}</span>
    </div>
  );
};
export default Chip;
