import { AvatarSize } from "@/components/Avatar";
import TheAccountNavigation from "@/components/TheAccountNavigation";
import UserIcon from "@/features/user/components/UserIcon";
import useMyUser from "@/features/user/hooks/useMyUser";
import useDisclosure from "@/hooks/useDisclosure";
import {
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { FC } from "react";

const MyMenu: FC = () => {
  const { user } = useMyUser();
  const { isOpen, toggle } = useDisclosure();
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: toggle,
    placement: "bottom-end",
    middleware: [shift({ padding: 8 })],
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context, { outsidePress: true }),
    useClick(context, { toggle: true }),
  ]);

  return (
    <div className="relative">
      <button ref={refs.setReference} onClick={toggle} {...getReferenceProps()}>
        <UserIcon user={user} size={AvatarSize.Normal} hasLink={false} />
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
          }}
        >
          <TheAccountNavigation />
        </div>
      )}
    </div>
  );
};
export default MyMenu;
