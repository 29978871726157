import { RootState } from "@/stores/rootReducer";
import { MyUserResponse } from "@/web-client/api";
import { useSelector } from "react-redux";

type UseMyUserReturn = {
  user?: MyUserResponse;
  isAuthenticated: boolean;
  isChanged: boolean;
};

const useMyUser = (): UseMyUserReturn => {
  const { user, isAuthenticated, isChanged } = useSelector(
    (state: RootState) => state.auth,
  );

  return {
    user,
    isAuthenticated,
    isChanged,
  };
};
export default useMyUser;
